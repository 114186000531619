import { ClipboardIcon, LinkIcon } from '@heroicons/react/20/solid'; // Icons from Heroicons

const TokenGenerateButton = ({ profile, tempLink, generateTempLink, copyToClipboard, loadingToken }) => {
  if (!profile?.isAdmin) return null; // Render nothing if not an admin

  const handleClick = () => {
    tempLink ? copyToClipboard(tempLink) : generateTempLink();
  };

  const buttonText = loadingToken
    ? "Generating..."
    : "Create Token Access Link";


  const Icon = tempLink ? ClipboardIcon : LinkIcon; // Conditional icon selection

  return (
    <button
      className="flex items-center space-x-2 bg-blue-600 p-1 text-white rounded-full hover:bg-blue-700 transition"
      onClick={handleClick}
      disabled={loadingToken}
      title={buttonText} // Mouseover text
    >
      <Icon className="h-5 w-5" /> {/* Icon size adjustment */}
    </button>
  );
};

export default TokenGenerateButton;