import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import Redirect404 from './pages/Redirect404';
import JobsFilterPage from './pages/JobsFilterPage';
import JobDetail from './components/jobs/JobDetail';
import AdminHomePage from './pages/AdminHome';
import Callback from './components/callback';
import { getWithExpiry, setWithExpiry } from './api/storageAccess';
import { client_url } from './config';
import axios from 'axios';
import { api_url } from './config';
import Unauthorized from './pages/Unauthorized';
import Spinner from './components/Spinner';
import LoginRequred from './pages/LoginRequired';

const App = () => {const navigate = useNavigate();
 const [token, setToken] = useState(getWithExpiry('token'));
 const [tempToken, setTempToken] = useState(getWithExpiry('tempToken'));
 const [loading, setLoading] = useState(true);
 const [darkMode, setDarkMode] = useState(() => localStorage.getItem('darkMode') === 'false');

   // Toggle dark mode and store the preference in localStorage
   const toggleDarkMode = () => {
    setDarkMode(prev => {
      const newMode = !prev;
      localStorage.setItem('darkMode', newMode);
      return newMode;
    });
  };

 useEffect(() => {
   const checkTempToken = async () => {
     const query = new URLSearchParams(window.location.search);
     const tempToken = query.get('token');

     if (tempToken) {
       try {
         const response = await axios.post(`${api_url}/api/validateTempToken`, { token: tempToken });
         if (response.status === 200) {
           setTempToken(tempToken); // Token is valid
           setWithExpiry('tempToken', tempToken); // Optionally store it
         } else {
           setTempToken(null); // Invalid token
         }
       } catch (error) {
         console.error('Invalid or expired token:', error);
         setTempToken(null);
       }
     }
     setLoading(false); // Finish loading
   };

   // Check if a token exists in local storage or in query params
   if (!token) {
     checkTempToken();
   } else {
     setLoading(false); // No need to validate temp token if we have a stored token
   }
 }, [token]);

 const [profile, setProfile] = useState(getWithExpiry('profile'));
 const [userIsAdmin, setUserIsAdmin] = useState(false);
// const [token, setToken] = useState('Bearer faketoken');
// const [profile, setProfile] = useState({groups:["Admin"]});
// const [userIsAdmin, setUserIsAdmin] = useState(true);

useEffect(() => {
  if (darkMode) document.documentElement.classList.add('dark');
  else document.documentElement.classList.remove('dark');
}, [darkMode]);


  useEffect(() => {

      // Initialize isAdmin to false
      let isAdmin = false;
    
      try {
        // Check if profile is not empty and is an object
        if (profile && typeof profile === 'object' && Array.isArray(profile.groups)) {
          // Check if "Admin" group is present in the groups array
          isAdmin = profile.groups.includes("Admin");
        }
      } catch (error) {
        console.error("Error parsing profile:", error);
        // Handle malformed profile or any other error
      }
    console.log("settinguser is admin- " + isAdmin);
    setUserIsAdmin(isAdmin);
  }, [profile]);


  // Helper function for login
  const login = () => {
    const clientId = '5d0ee5c0-5851-013d-6e8f-1eb3980202ff154263';
    const redirectUri = `${client_url}/callback`;
    const oneloginDomain = 'kontana.onelogin.com';
    const authUrl = `https://${oneloginDomain}/oidc/2/auth?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=openid%20profile%20email%20groups`;

    window.location.href = authUrl;
  };

  // Helper function for logout
  const logout = () => {
    console.log("Logging out");
    localStorage.removeItem('token');
    localStorage.removeItem('profile');
    setToken(null);
    setProfile(null);
    navigate('/');
  };

  // Handle login and logout based on the route
  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath === '/login') {
      login();
    } else if (currentPath === '/logout') {
      logout();
    }
  }, []);

  const isLoggedIn=() =>{
    return (token || tempToken);
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="h-screen overflow-visible">
      {<NavigationBar token={token} profile={profile} darkMode={darkMode} toggleDarkMode={toggleDarkMode}  />}
      <Routes>
        <Route path="/callback" element={<Callback setToken={setToken} setProfile={setProfile} />} />
        
        {/* Admin route require token */}
        <Route path="/admin" element={ isLoggedIn() ? (userIsAdmin ? <AdminHomePage token={token} darkMode={darkMode} /> : <Unauthorized />) : <LoginRequred/> } />

        {/* login and logout routes just display a spinner, the logic is handled above with the useEffect hooks since we are using onelogin */}
        <Route path="/login" element={<Spinner />} />
        <Route path="/logout" element={<Spinner />} />
  
        {/* General routes */}
        <Route path="/" element={isLoggedIn() ? <JobsFilterPage token={token} tempToken={tempToken} /> : <LoginRequred/>} />
        <Route path="/jobs" element={isLoggedIn() ? <JobsFilterPage token={token} tempToken={tempToken}/> : <LoginRequred/>} />
        <Route path="/job/:uuid" element={isLoggedIn() ?<JobDetail token={token} tempToken={tempToken} profile={profile}/>: <LoginRequred/>} />
        <Route path="*" element={isLoggedIn() ?<Redirect404 />: <LoginRequred/>} />
      </Routes>
    </div>
  );
};

export default App;
