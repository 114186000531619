import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { api_url } from '../config';
import { useNavigate } from 'react-router-dom';

const ContractForm = (props) => {
  const [contracts, setContracts] = useState([]);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [newContractName, setNewContractName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchContracts();
  }, []);

  const fetchContracts = async () => {
    try {
      const response = await axios.get(api_url + '/api/contracts');
      setContracts(response.data);
    } catch (error) {
      console.error("Error fetching contracts:", error);
    }
  };

  const handleDeleteContract = async (id) => {
    try {
      await axios.delete(api_url + `/api/contract/${id}`);
      setContracts(contracts.filter(contract => contract._id !== id));
    } catch (error) {
      console.error("Error deleting contract:", error);
    }
  };

  const handleAddContract = async () => {
    try {
      const response = await axios.post(api_url + '/api/contract', { name: newContractName });
      setContracts([...contracts, response.data]);
      setShowAddDialog(false);
      setNewContractName('');
    } catch (error) {
      console.error("Error adding contract:", error);
    }
  };

  const handleAddLCAT = (contractId, contractName) => {
    // Navigate to LCAT edit form for the selected contract
    navigate(`/admin/contracts/${contractId}/lcats`, { state: { contractName } });
  };

  return (

    <div className="w-full p-4">
    <div className="w-full md:w-3/4 lg:w-2/3 mx-auto">
      <h2 className="text-xl font-bold mb-4">Contracts</h2>

      <ul className="mb-4">
        {contracts.map(contract => (
          <li key={contract._id} className="flex justify-between items-center p-2 bg-gray-100 rounded mb-2">
            <span>{contract.name}</span>
            <div className="flex space-x-4">
              <button
                className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-700"
                onClick={() => handleAddLCAT(contract._id, contract.name)}
              >
                Edit LCATs
              </button>
              <button
                className="text-red-500 hover:text-red-700"
                onClick={() => handleDeleteContract(contract._id)}
              >
                X
              </button>
            </div>
          </li>
        ))}
      </ul>

      <button
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
        onClick={() => setShowAddDialog(true)}
      >
        Add New Contract
      </button>

      {showAddDialog && (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-75">
          <div className="bg-white p-6 rounded">
            <h3 className="text-lg font-semibold mb-4">Add New Contract</h3>
            <input
              type="text"
              className="border border-gray-300 rounded w-full p-2 mb-4"
              value={newContractName}
              onChange={(e) => setNewContractName(e.target.value)}
              placeholder="Enter contract name"
            />
            <div className="flex justify-end">
              <button
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
                onClick={() => setShowAddDialog(false)}
              >
                Cancel
              </button>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
                onClick={handleAddContract}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}
      </div>
    </div>
  );
};

export default ContractForm;
