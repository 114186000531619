import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { api_url } from '../config';

const LCATForm = (props) => {
  const [lcats, setLCATs] = useState([]);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [newLCAT, setNewLCAT] = useState({ name: '', noDegree: 0, bachelors: 0, masters: 0, doctorate: 0 });
  const [editingLCAT, setEditingLCAT] = useState(null);
  const [contractName, setContractName] = useState('');
  // const { contractId } = useParams();

  useEffect(() => {
    fetchLCATs();
    fetchContractName();
  }, []);

  // Fetch LCATs and filter by contractId
  const fetchLCATs = async () => {
    // alert(props.activeContractId);
    try {
      const response = await axios.get(`${api_url}/api/lcats/${props.activeContractId}`,
        {
          headers: {
            Authorization: `Bearer ${props.token}` // Pass the token here
          }
        });
     setLCATs(response.data);
    } catch (error) {
      console.error("Error fetching LCATs:", error);
    }
  };

  // Fetch contract name
  const fetchContractName = async () => {
    try {
      const response = await axios.get(`${api_url}/api/contract/${props.activeContractId}`,
        {
          headers: {
            Authorization: `Bearer ${props.token}` // Pass the token here
          }
        });
      setContractName(response.data.name);
    } catch (error) {
      console.error("Error fetching contract name:", error);
    }
  };

  const handleAddLCAT = async () => {
    try {
      const response = await axios.post(`${api_url}/api/lcat`, { ...newLCAT, contract: props.contractId },
        {
          headers: {
            Authorization: `Bearer ${props.token}` // Pass the token here
          }
        });
      setLCATs([...lcats, response.data]);
      setShowAddDialog(false);
      setNewLCAT({ name: '', noDegree: 0, bachelors: 0, masters: 0, doctorate: 0 });
    } catch (error) {
      console.error("Error adding LCAT:", error);
    }
  };

  const handleEditLCAT = (lcat) => {
    setEditingLCAT(lcat);
    setNewLCAT({
      name: lcat.name,
      noDegree: lcat.noDegree,
      bachelors: lcat.bachelors,
      masters: lcat.masters,
      doctorate: lcat.doctorate,
    });
    setShowEditDialog(true);
  };

  const handleUpdateLCAT = async () => {
    try {
      const response = await axios.put(`${api_url}/api/lcat/${editingLCAT._id}`, newLCAT,
        {
          headers: {
            Authorization: `Bearer ${props.token}` // Pass the token here
          }
        });
      setLCATs(lcats.map(lcat => (lcat._id === editingLCAT._id ? response.data : lcat)));
      setShowEditDialog(false);
      setEditingLCAT(null);
      setNewLCAT({ name: '', noDegree: 0, bachelors: 0, masters: 0, doctorate: 0 });
    } catch (error) {
      console.error("Error updating LCAT:", error);
    }
  };

  const handleDeleteLCAT = async (id,name) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete the lcat: ${name}?`);
    if (!confirmDelete) {
      return; // Do nothing if the user cancels
    }
    try {
      await axios.delete(`${api_url}/api/lcat/${id}`,
        {
          headers: {
            Authorization: `Bearer ${props.token}` // Pass the token here
          }
        });
      setLCATs(lcats.filter(lcat => lcat._id !== id));
    } catch (error) {
      console.error("Error deleting LCAT:", error);
    }
  };

  return (
    <div className="w-full p-4">
      <div className="w-full md:w-3/4 lg:w-2/3 mx-auto">
        <h2 className="text-xl font-bold mb-4">{contractName} - LCATs</h2>

        <ul className="mb-4">
          {lcats.map(lcat => (
            <li key={lcat._id} className="flex justify-between items-center p-2 bg-gray-100 rounded mb-2">
              <span>{lcat.name}</span>
              <div className="flex space-x-4">
                <button
                  className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-700"
                  onClick={() => handleEditLCAT(lcat)}
                >
                  Edit
                </button>
                <button
                  className="text-red-500 hover:text-red-700"
                  onClick={() => handleDeleteLCAT(lcat._id, lcat.name)}
                >
                  X
                </button>
              </div>
            </li>
          ))}
        </ul>

        <button
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
          onClick={() => setShowAddDialog(true)}
        >
          Add New LCAT
        </button>

        {/* Add LCAT Dialog */}
        {showAddDialog && (
          <div className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-75">
            <div className="bg-white p-6 rounded">
              <h3 className="text-lg font-semibold mb-4">Add New LCAT</h3>
              <input
                type="text"
                className="border border-gray-300 rounded w-full p-2 mb-4"
                value={newLCAT.name}
                onChange={(e) => setNewLCAT({ ...newLCAT, name: e.target.value })}
                placeholder="Enter LCAT name"
              />
              <label>No Degree Experience</label>
              <input
                type="number"
                className="border border-gray-300 rounded w-full p-2 mb-4"
                value={newLCAT.noDegree}
                onChange={(e) => setNewLCAT({ ...newLCAT, noDegree: e.target.value })}
              />
              <label>Bachelors Experience</label>
              <input
                type="number"
                className="border border-gray-300 rounded w-full p-2 mb-4"
                value={newLCAT.bachelors}
                onChange={(e) => setNewLCAT({ ...newLCAT, bachelors: e.target.value })}
              />
              <label>Masters Experience</label>
              <input
                type="number"
                className="border border-gray-300 rounded w-full p-2 mb-4"
                value={newLCAT.masters}
                onChange={(e) => setNewLCAT({ ...newLCAT, masters: e.target.value })}
              />
              <label>Doctorate Experience</label>
              <input
                type="number"
                className="border border-gray-300 rounded w-full p-2 mb-4"
                value={newLCAT.doctorate}
                onChange={(e) => setNewLCAT({ ...newLCAT, doctorate: e.target.value })}
              />
              <div className="flex justify-end">
                <button
                  className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
                  onClick={() => setShowAddDialog(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
                  onClick={handleAddLCAT}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Edit LCAT Dialog */}
        {showEditDialog && (
          <div className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-75">
            <div className="bg-white p-6 rounded">
              <h3 className="text-lg font-semibold mb-4">Edit LCAT</h3>
              <input
                type="text"
                className="border border-gray-300 rounded w-full p-2 mb-4"
                value={newLCAT.name}
                onChange={(e) => setNewLCAT({ ...newLCAT, name: e.target.value })}
                placeholder="Enter LCAT name"
              />
              <label>No Degree Experience</label>
              <input
                type="number"
                className="border border-gray-300 rounded w-full p-2 mb-4"
                value={newLCAT.noDegree}
                onChange={(e) => setNewLCAT({ ...newLCAT, noDegree: e.target.value })}
              />
              <label>Bachelors Experience</label>
              <input
                type="number"
                className="border border-gray-300 rounded w-full p-2 mb-4"
                value={newLCAT.bachelors}
                onChange={(e) => setNewLCAT({ ...newLCAT, bachelors: e.target.value })}
              />
              <label>Masters Experience</label>
              <input
                type="number"
                className="border border-gray-300 rounded w-full p-2 mb-4"
                value={newLCAT.masters}
                onChange={(e) => setNewLCAT({ ...newLCAT, masters: e.target.value })}
              />
              <label>Doctorate Experience</label>
              <input
                type="number"
                className="border border-gray-300 rounded w-full p-2 mb-4"
                value={newLCAT.doctorate}
                onChange={(e) => setNewLCAT({ ...newLCAT, doctorate: e.target.value })}
              />
              <div className="flex justify-end">
                <button
                  className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
                  onClick={() => setShowEditDialog(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
                  onClick={handleUpdateLCAT}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LCATForm;
