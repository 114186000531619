// Filters used for searching through data

export const getFilteredJobs = (jobs, queryState) => {
    const results = jobs.filter(job => {
        // If no query, return all jobs
        if (queryState.query === "") {
          return jobs
        }
        else
          {
            if ( job.name !== undefined && job.name.toLowerCase().includes(queryState.query.toLowerCase()) === true){
              return true;
            } else if ( job.description !== undefined && job.description.toLowerCase().includes(queryState.query.toLowerCase()) === true){
              return true;
            } else if ( job.clearanceLevel !== undefined && job.clearanceLevel.toLowerCase().includes(queryState.query.toLowerCase()) === true){
              return true;
            } else if ( job.lcat !== undefined && job.lcat.name.toLowerCase().includes(queryState.query.toLowerCase()) === true){
              return true;
            } else if ( job.contract !== undefined && job.contract.name.toLowerCase().includes(queryState.query.toLowerCase()) === true){
              return true;
            } else if ( job.tto !== undefined && job.tto.toLowerCase().includes(queryState.query.toLowerCase()) === true){
              return true;
            } else if ( job.location !== undefined && job.location.name.toLowerCase().includes(queryState.query.toLowerCase()) === true){
              return true;
            } else if ( (">".includes(queryState.query.toLowerCase()) === true) || ("<".includes(queryState.query.toLowerCase()) === true) || ("=".includes(queryState.query.toLowerCase()) === true) ) {
              // If this job has a rate, allow searching for keywords ">", "<", or "="
              //return (job.rate.)
              return true;
            }
             else if ( job.teleworkHoursWeekly !== undefined && job.teleworkHoursWeekly > 0){
              // If this job has remote/telework hours, allow searching for keywords "remote" or "telework"
              return (("remote".includes(queryState.query.toLowerCase()) === true) || ("telework".includes(queryState.query.toLowerCase()) === true));
            } 
            return false;
    } })
    return results;
    }

    export const getOpenJobsOnly = (jobs) => {
      const results = jobs.filter(job => {
        if ( job.status !== undefined && job.status !== "Closed"){
          return true;
        }
      })
      return results;
    }

    export const getFilteredContracts = (contracts, queryState) => {
        const results = contracts.filter(contract => {
            // If no query, return all contracts
            if (queryState.query === "") {
              return contracts
            }
            else
              {
                if ( contract.name !== undefined && contract.name.toLowerCase().includes(queryState.query.toLowerCase()) === true){
                    return true;
                } else if ( contract.abbreviation !== undefined && contract.abbreviation.toLowerCase().includes(queryState.query.toLowerCase()) === true){
                  return true;
              }
                return false;
        } })
        return results;
    }
  
    export const getFilteredCompanies = (companies, queryState) => {
      const results = companies.filter(company => {
          // If no query, return all companies
          if (queryState.query === "") {
            return companies
          }
          else
            {
              if ( company.name !== undefined && company.name.toLowerCase().includes(queryState.query.toLowerCase()) === true){
                  return true;
              } else if ( company.abbreviation !== undefined && company.abbreviation.toLowerCase().includes(queryState.query.toLowerCase()) === true){
                return true;
            }
              return false;
      } })
      return results;
  }
  
  export const getFilteredLocations = (locations, queryState) => {
    const results = locations.filter(location => {
        // If no query, return all locations
        if (queryState.query === "") {
          return locations
        }
        else
          {
            if ( location.name !== undefined && location.name.toLowerCase().includes(queryState.query.toLowerCase()) === true){
                return true;
            } else if ( location.address !== undefined && location.address.toLowerCase().includes(queryState.query.toLowerCase()) === true){
              return true;
          }
            return false;
    } })
    return results;
  }
  

  export const getFilteredLCATs = (lcats, queryState) => {
    const results = lcats.filter(lcat => {
        // If no query, return all lcats
        if (queryState.query === "") {
          return lcats
        }
        else
          {
            if ( lcat.name !== undefined && lcat.name.toLowerCase().includes(queryState.query.toLowerCase()) === true){
                return true;
            } else if ( lcat.abbreviation !== undefined && lcat.abbreviation.toLowerCase().includes(queryState.query.toLowerCase()) === true){
              return true;
          }
            return false;
    } })
    return results;
  }
  

  // export const getFilteredRates = (rates, queryState) => {
  //   const results = rates.filter(rate => {
  //       // If no query, return all rates
  //       if (queryState.query === "") {
  //         return rates
  //       }
  //       else
  //         {
  //           if ( rate.contract !== undefined && rate.contract.name.toLowerCase().includes(queryState.query.toLowerCase()) === true){
  //               return true;
  //           } else if ( rate.lcat !== undefined && rate.lcat.name.toLowerCase().includes(queryState.query.toLowerCase()) === true){
  //             return true;
  //         }
  //           return false;
  //   } })
  //   return results;
  // }

  export const getFilteredUsers = (users, queryState) => {
    const results = users.filter(user => {
        // If no query, return all user
        if (queryState.query === "") {
          return users
        }
        else
          {
            if ( user.name !== undefined && user.name.toLowerCase().includes(queryState.query.toLowerCase()) === true){
                return true;
            } else if ( user.notes !== undefined && user.notes.toLowerCase().includes(queryState.query.toLowerCase()) === true){
              return true;
            } else if ( user.email !== undefined && user.email.toLowerCase().includes(queryState.query.toLowerCase()) === true){
              return true;
            } else if ( user.role !== undefined && user.role.toLowerCase().includes(queryState.query.toLowerCase()) === true){
              return true;
          }
            return false;
    } })
    return results;
}
  