import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { UsersIcon } from '@heroicons/react/20/solid'
import { UserCircleIcon } from '@heroicons/react/20/solid'
import { FunnelIcon } from '@heroicons/react/20/solid'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import logo from '../resources/logo_only.png'
import { NavLink } from 'react-router-dom'

const navigation = [
  { name: 'Kontana Jobs', href: '/jobs', current: false },
]

const navigationLoggedInAll = [
  { name: 'Kontana Jobs', href: '/jobs', current: false },
]

const navigationLoggedInAdmin = [
  { name: 'Kontana Jobs', href: '/jobs', current: false },
  { name: 'Administration', href: '/admin', current: false },
]

const smallNavigationProfile = [
  { name: 'Login', href: '/login', current: true }
]

const smallNavigationProfileLoggedIn = [
  { name: 'Logout', href: '/logout', current: true }
]

 function classNames(...classes) {
   return classes.filter(Boolean).join(' ')
 }

function navigationItems(state){
  if ( state.loggedIn && state.profile.role == 'admin' ){
    return navigationLoggedInAdmin;
  } else if (state.loggedIn) {
    return navigationLoggedInAll;
  } else{
    return navigation;
  }
}

function smallNavigationProfileItems(state){
  if ( state.loggedIn ){
    return smallNavigationProfileLoggedIn;
  }else{
    return smallNavigationProfile;
  }
}

function renderUserIcon(state){

  // If logged in, show user icon, else show users icon
  if ( state.loggedIn ) {
    return (
      <UserCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
    );
  } else {
    return (
      <UsersIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
      );
  }
}
function renderMenuItems(state){
  
  // Is logged in, show profile and logout
  if ( state.loggedIn ){

    let profile = state.profile;//JSON.parse(state.profile);
    return (
      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">        
      <Menu.Item>
      {({ active }) => (
        <a
          href="/profile"
          className={classNames(active ? 'bg-gray-200' : '', 'block px-4 py-2 text-sm text-gray-700')}
        >
          {profile.name}
        </a>
       )}
    </Menu.Item>

    <Menu.Item>
      {({ active }) => (
        <a
          href="/logout"
          className={classNames(active ? 'bg-red-800' : 'bg-red-900', 'block px-4 py-2 text-sm text-gray-100')}
        >
          Log out
        </a>
      )}
    </Menu.Item>
    </Menu.Items>);
  }else 
  // Not logged in, show login
  {
    return(
      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">        
          <Menu.Item>
      {({ active }) => (
        <a
          href="/login"
          className={classNames(active ? 'bg-gray-200' : '', 'block px-4 py-2 text-sm text-gray-700')}
        >
          Log in
        </a>
      )}
    </Menu.Item>
      </Menu.Items>
 );                     
  }
}

 export default function NavigationBar(props) {
  
  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-full px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <a href="/" ><img className="block h-8 w-auto lg:block"
                    src={logo}
                    alt="Kontana"/></a>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    
                    {navigationItems(props.state).map((item) => (
                      <NavLink key={item.name} 
                            className={({ isActive, isPending }) => classNames(
                              isPending ? "bg-gray-900 text-white" : isActive ? 
                              "bg-gray-900 text-white" : "text-gray-300 hover:bg-gray-700 hover:text-white ", 
                              " rounded-md px-3 py-2 text-sm font-medium")
  }
                              to={item.href}>
                              {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>
       
              <div className="invisible sm:visible absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* <button
                  type="button"
                  className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">Open user menu</span>
                      {renderUserIcon(props.state)}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    {renderMenuItems(props.state)}
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigationItems(props.state).map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}

            {smallNavigationProfileItems(props.state).map((item) => (
              <Disclosure.Button
                key={item.name}
                as="a"
                href={item.href}
                className={classNames(
                  item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'block rounded-md px-3 py-2 text-base font-medium'
                )}
                aria-current={item.current ? 'page' : undefined}>
              {item.name}
            </Disclosure.Button>
            ))}
            </div>
  
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}