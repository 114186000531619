import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { api_url } from '../config';

const AdminHomePage = (props) => {
    const [locationsCount, setLocationsCount] = useState(0);
    const [contractsCount, setContractsCount] = useState(0);
    const [jobsCount, setJobsCount] = useState(0);

    useEffect(() => {
        fetchLocationCount();
        fetchContractCount();
        fetchJobsCount();
    }, []);

    useEffect(() => {
        props.setShowNavigationBar(true);
    }, []);
    
    const fetchLocationCount = async () => {
        try {
            const response = await axios.get(`${api_url}/api/locations`);
            setLocationsCount(response.data.length);
        } catch (error) {
            console.error("Error fetching location count:", error);
        }
    };

    const fetchContractCount = async () => {
        try {
            const response = await axios.get(`${api_url}/api/contracts`);
            setContractsCount(response.data.length);
        } catch (error) {
            console.error("Error fetching contract count:", error);
        }
    };

    const fetchJobsCount = async () => {
        try {
            const response = await axios.get(`${api_url}/api/jobs`);
            setJobsCount(response.data.length);
        } catch (error) {
            console.error("Error fetching jobs count:", error);
        }
    };

    return (
        <div className="flex flex-col items-center min-h-screen p-8 bg-gray-100">
            <div className="w-full max-w-md mt-8">
                <h1 className="text-2xl font-bold text-center mb-8 text-gray-700">Kontana Jobs Administration</h1>
                <div className="border border-gray-300 p-6 rounded-lg shadow-lg bg-white">
                    <button 
                        className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-6"
                        onClick={() => window.location.href='/admin/locations'}>
                        Locations ({locationsCount})
                    </button>

                    <button 
                        className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-6"
                        onClick={() => window.location.href='/admin/contracts'}>
                        Contracts ({contractsCount})
                    </button>

                    <button 
                        className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-6"
                        onClick={() => window.location.href='/admin/jobs'}>
                        Jobs ({jobsCount})
                    </button>

                    <button 
                        className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        onClick={() => window.location.href='/admin/upload-jobs'}>
                        Upload Jobs
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AdminHomePage;
