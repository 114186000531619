import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { api_url } from '../config';

const JobForm = (props) => {
  const [jobs, setJobs] = useState([]);
  const [lcats, setLCATs] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [locations, setLocations] = useState([]);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [newJob, setNewJob] = useState({
    name: '',
    description: '',
    clearanceLevel: 'TS/SCI with FSP',
    teleworkHoursWeekly: 0,
    contract: '',
    lcat: '',
    tto: '',
    location: '',
    status: ''
  });

  const [editingJob, setEditingJob] = useState(null);

  useEffect(() => {
    fetchJobs();
    fetchLCATs();
    fetchContracts();
    fetchLocations();
  }, []);

  // Fetch jobs
  const fetchJobs = async () => {
    try {
      const response = await axios.get(`${api_url}/api/jobs`);
      setJobs(response.data);
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  // Fetch LCATs
  const fetchLCATs = async () => {
    try {
      const response = await axios.get(`${api_url}/api/lcats`);
      setLCATs(response.data);
    } catch (error) {
      console.error('Error fetching LCATs:', error);
    }
  };

  // Fetch Contracts
  const fetchContracts = async () => {
    try {
      const response = await axios.get(`${api_url}/api/contracts`);
      setContracts(response.data);
    } catch (error) {
      console.error('Error fetching contracts:', error);
    }
  };

  // Fetch Locations
  const fetchLocations = async () => {
    try {
      const response = await axios.get(`${api_url}/api/locations`);
      setLocations(response.data);
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  // Handle job edit button
  const handleEditJob = (job) => {
    setEditingJob(job);
    setNewJob({
      name: job.name,
      description: job.description,
      clearanceLevel: job.clearanceLevel,
      teleworkHoursWeekly: job.teleworkHoursWeekly,
      contract: job.contract?._id || '',
      lcat: job.lcat?._id || '',
      tto: job.tto,
      location: job.location?._id || '',
      status: job.status
    });
    setShowEditDialog(true);
  };

  // Handle job update
  const handleUpdateJob = async () => {
    try {
      const response = await axios.put(`${api_url}/api/jobs/${editingJob._id}`, newJob);
      setJobs(jobs.map(job => (job._id === editingJob._id ? response.data : job)));
      setShowEditDialog(false);
      setEditingJob(null);
    } catch (error) {
      console.error('Error updating job:', error);
    }
  };

  // Handle adding a new job
  const handleAddJob = async () => {
    try {
      const response = await axios.post(`${api_url}/api/jobs`, newJob);
      setJobs([...jobs, response.data]);
      setShowEditDialog(false);
      setNewJob({
        name: '',
        description: '',
        clearanceLevel: 'TS/SCI with FSP',
        teleworkHoursWeekly: 0,
        contract: '',
        lcat: '',
        tto: '',
        location: '',
        status: ''
      });
    } catch (error) {
      console.error('Error adding job:', error);
    }
  };

  // Handle job delete
  const handleDeleteJob = async (id) => {
    try {
      await axios.delete(`${api_url}/api/jobs/${id}`);
      setJobs(jobs.filter(job => job._id !== id));
    } catch (error) {
      console.error('Error deleting job:', error);
    }
  };

  // Open the modal for adding a new job
  const handleOpenAddDialog = () => {
    setEditingJob(null); // No job is being edited, so set to null
    setNewJob({
      name: '',
      description: '',
      clearanceLevel: 'TS/SCI with FSP',
      teleworkHoursWeekly: 0,
      contract: '',
      lcat: '',
      tto: '',
      location: '',
      status: ''
    });
    setShowEditDialog(true);
  };

  return (
    <div className="w-full p-4">
      <div className="w-full md:w-3/4 lg:w-2/3 mx-auto">
        <h2 className="text-xl font-bold mb-4">Jobs</h2>

        <ul className="mb-4">
          {jobs.map(job => (
            <li key={job._id} className="flex justify-between items-center p-2 bg-gray-100 rounded mb-2">
              <span>{job.name}</span>
              <div className="flex space-x-4">
                <button
                  className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-700"
                  onClick={() => handleEditJob(job)}
                >
                  Edit
                </button>
                <button
                  className="text-red-500 hover:text-red-700"
                  onClick={() => handleDeleteJob(job._id)}
                >
                  X
                </button>
              </div>
            </li>
          ))}
        </ul>

        <button
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
          onClick={handleOpenAddDialog}
        >
          Add New Job
        </button>

        {/* Edit/Add Job Dialog */}
        {showEditDialog && (
          <div className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-75">
            <div className="bg-white p-6 rounded">
              <h3 className="text-lg font-semibold mb-4">
                {editingJob ? 'Edit Job' : 'Add New Job'}
              </h3>

              {/* Job Name */}
              <input
                type="text"
                className="border border-gray-300 rounded w-full p-2 mb-4"
                value={newJob.name}
                onChange={(e) => setNewJob({ ...newJob, name: e.target.value })}
                placeholder="Job Name"
              />

              {/* Description */}
              <textarea
                className="border border-gray-300 rounded w-full p-2 mb-4"
                value={newJob.description}
                onChange={(e) => setNewJob({ ...newJob, description: e.target.value })}
                placeholder="Job Description"
              />

              {/* Clearance Level */}
              <input
                type="text"
                className="border border-gray-300 rounded w-full p-2 mb-4"
                value={newJob.clearanceLevel}
                onChange={(e) => setNewJob({ ...newJob, clearanceLevel: e.target.value })}
                placeholder="Clearance Level"
              />

              {/* Telework Hours */}
              <input
                type="number"
                className="border border-gray-300 rounded w-full p-2 mb-4"
                value={newJob.teleworkHoursWeekly}
                onChange={(e) => setNewJob({ ...newJob, teleworkHoursWeekly: e.target.value })}
                placeholder="Telework Hours Weekly"
              />

              {/* Contract Dropdown */}
              <select
                className="border border-gray-300 rounded w-full p-2 mb-4"
                value={newJob.contract}
                onChange={(e) => setNewJob({ ...newJob, contract: e.target.value })}
              >
                <option value="">Select Contract</option>
                {contracts.map(contract => (
                  <option key={contract._id} value={contract._id}>
                    {contract.name}
                  </option>
                ))}
              </select>

              {/* LCAT Dropdown */}
              <select
                className="border border-gray-300 rounded w-full p-2 mb-4"
                value={newJob.lcat}
                onChange={(e) => setNewJob({ ...newJob, lcat: e.target.value })}
              >
                <option value="">Select LCAT</option>
                {lcats.map(lcat => (
                  <option key={lcat._id} value={lcat._id}>
                    {lcat.name}
                  </option>
                ))}
              </select>

              {/* Location Dropdown */}
              <select
                className="border border-gray-300 rounded w-full p-2 mb-4"
                value={newJob.location}
                onChange={(e) => setNewJob({ ...newJob, location: e.target.value })}
              >
                <option value="">Select Location</option>
                {locations.map(location => (
                  <option key={location._id} value={location._id}>
                    {location.name}
                  </option>
                ))}
              </select>

              {/* Save / Cancel Buttons */}
              <div className="flex justify-end space-x-4">
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                  onClick={editingJob ? handleUpdateJob : handleAddJob}
                >
                  {editingJob ? 'Update Job' : 'Add Job'}
                </button>
                <button
                  className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
                  onClick={() => setShowEditDialog(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobForm;
