
import JobRow from './JobRow';
import { getOpenJobsOnly } from '../../util/filters';

export default function JobTable(props) {
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {props.jobs && getOpenJobsOnly(props.jobs).map((job) => (
            <JobRow key={job._id} job={job} state={props.state} favorites={props.favorites} addFavorite={props.addFavorite} deleteFavorite={props.deleteFavorite}/>
        ))}
        {props.jobs.length === 0 ? <li className="px-6 py-4 whitespace-nowrap"> No matching openings found </li>: <></>}
      </ul>
    </div>
  )
}