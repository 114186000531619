import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { api_url, client_url } from '../config'; // Update with your URLs

const TokenAdminForm = (props) => {
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const usernameRef = useRef();
  const expirationRef = useRef();

  // Fetch tokens from the server
  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const headers = props.token ? { Authorization: `Bearer ${props.token}` } : {};
        const response = await axios.get(`${api_url}/api/listTempTokens`, { headers });
        setTokens(response.data);
      } catch (err) {
        setError('Failed to fetch tokens');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchTokens();
  }, [props.token]);

  // Handle revoking a token
  const revokeToken = async (token) => {
    try {
      const headers = props.token ? { Authorization: `Bearer ${props.token}` } : {};
      await axios.post(`${api_url}/api/revokeTempToken`, { token }, { headers });
      setTokens((prevTokens) => prevTokens.filter((t) => t.token !== token));
    } catch (err) {
      setError('Failed to revoke token');
      console.error(err);
    }
  };

  // Handle generating a token
  const generateToken = async () => {
    const username = usernameRef.current.value.trim();
    const expirationHours = parseInt(expirationRef.current.value) || 24;

    if (!username) {
      setError('Username is required');
      return;
    }

    if (expirationHours < 1 || expirationHours > 168) {
      setError('Expiration must be between 1 and 168 hours.');
      return;
    }

    try {
      const headers = props.token ? { Authorization: `Bearer ${props.token}` } : {};
      const response = await axios.post(
        `${api_url}/api/generateTempToken`,
        { username, expirationHours },
        { headers }
      );

      const newToken = response.data.token;
      const newTokenData = {
        token: newToken,
        username,
        createdAt: new Date(),
        expiration: new Date(Date.now() + expirationHours * 60 * 60 * 1000),
      };

      setTokens((prevTokens) => [...prevTokens, newTokenData]);
      usernameRef.current.value = ''; // Clear username field
      expirationRef.current.value = '24'; // Reset expiration to default
      setIsModalOpen(false); // Close the modal
    } catch (err) {
      setError('Failed to generate token');
      console.error(err);
    }
  };



  const copyToClipboard = async (currentUrl, token) => {
    try {
      const separator = currentUrl.includes('?') ? '&' : '?';
      const urlWithToken = `${currentUrl}${separator}token=${token}`;

      await navigator.clipboard.writeText(urlWithToken);
      alert('Link copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy: ', err);
      alert('Failed to copy the link. Please try again.');
    }
  };

  return (
    <div className="w-full p-4">
      <div className="w-full md:w-3/4 lg:w-2/3 mx-auto">
        <h1 className="text-2xl font-bold mb-4 text-center">Temporary Access Tokens</h1>

        {/* Generate New Token Button */}
        <button
          className="flex items-center gap-2 bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mb-6"
          onClick={() => setIsModalOpen(true)}
        >
          <span className="text-xl">+</span> Generate New Token
        </button>

        <h1 className="text-xl font-bold mb-4 text-left">Active Tokens</h1>
        {tokens.length === 0 ? (
          <p>No temporary tokens issued.</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="table-auto w-full border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-200 text-left">
                  <th className="border border-gray-300 px-4 py-2">Username</th>
                  <th className="border border-gray-300 px-4 py-2">Created At</th>
                  <th className="border border-gray-300 px-4 py-2">Expires At</th>
                  <th className="border border-gray-300 px-4 py-2">Link</th>
                  <th className="border border-gray-300 px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {tokens.map((token) => (
                  <tr key={token.token}>
                    <td className="border border-gray-300 px-4 py-2">{token.username}</td>
                    <td className="border border-gray-300 px-4 py-2">
                      {new Date(token.createdAt).toLocaleString()}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {new Date(token.expiration).toLocaleString()}
                    </td>
                    <td className="border border-gray-300 px-4 py-2 break-all">
                      <button
                        onClick={() => copyToClipboard(token.currentUrl, token.token)}
                        className="text-blue-600 hover:underline focus:outline-none"
                      >
                        Link
                      </button>
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      <button
                        className="bg-red-500 text-white px-4 py-2 rounded"
                        onClick={() => revokeToken(token.token)}
                      >
                        Revoke
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Modal Dialog */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded shadow-lg w-full max-w-lg">
              <h2 className="text-xl font-bold mb-4">Generate New Token</h2>

              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-1">Username</label>
                <input
                  ref={usernameRef}
                  type="text"
                  placeholder="Enter username"
                  className="w-full px-4 py-2 border border-gray-300 rounded"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-1">Expiration (Hours)</label>
                <input
                  ref={expirationRef}
                  type="number"
                  min="1"
                  max="168"
                  defaultValue="24"
                  placeholder="Expiration (Hours)"
                  className="w-full px-4 py-2 border border-gray-300 rounded"
                />
              </div>

              <div className="flex justify-end gap-4">
                <button
                  className="bg-gray-300 hover:bg-gray-400 text-black py-2 px-4 rounded"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded"
                  onClick={generateToken}
                >
                  Generate
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TokenAdminForm;
