import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { api_url } from '../config';
import JobForm from './JobForm'; // Assuming you have these components
import ContractForm from './ContractForm';
import LocationForm from './LocationForm';
import { useLocation } from 'react-router-dom';
import JobUploadForm from './JobUploadForm';
import { useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import LCATForm from './LCATForm';
import ImportKRForm from './ImportKRForm';
import ImportIDForm from './ImportIDForm';
import ImportTKOForm from './ImportTKOForm';
import ImportTBForm from './ImportTBForm';
import ImportTP2Form from './ImportTP2Form';
import TokenAdminForm from './TokenAdminForm';

const AdminHomePage = (props) => {
    const location = useLocation();
    const [locationsCount, setLocationsCount] = useState(0);
    const [contractsCount, setContractsCount] = useState(0);
    const [jobsCount, setJobsCount] = useState(0);
    const [activeForm, setActiveForm] = useState("admin"); // To track the active form
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeContractId, setActiveContractId] = useState(null);

    useEffect(() => {
        fetchLocationCount();
        fetchContractCount();
        fetchJobsCount();

        // Parse query params on mount and when searchParams change
        const parsed = queryString.parse(location.search);
        if (parsed.activeForm) {
            setActiveForm(parsed.activeForm);
        }else{
            setActiveForm("admin");
        }
    }, [location.search]); // Re-run effect when the search params change
    
    useEffect(() => {
      if (props.darkMode) {
        document.documentElement.classList.add('dark');
        console.log("dark mode");
      }
      else document.documentElement.classList.remove('dark');
    }, [props.darkMode]);

    const fetchLocationCount = async () => {
        try {
            const response = await axios.get(`${api_url}/api/locations`,
                {
                  headers: {
                    Authorization: `Bearer ${props.token}` // Pass the token here
                  }
                });
            setLocationsCount(response.data.length);
        } catch (error) {
            console.error("Error fetching location count:", error);
        }
    };

    const fetchContractCount = async () => {
        try {
            const response = await axios.get(`${api_url}/api/contracts`,
                {
                  headers: {
                    Authorization: `Bearer ${props.token}` // Pass the token here
                  }
                });
            setContractsCount(response.data.length);
        } catch (error) {
            console.error("Error fetching contract count:", error);
        }
    };

    const fetchJobsCount = async () => {
        try {
            const response = await axios.get(`${api_url}/api/jobs`,
                {
                  headers: {
                    Authorization: `Bearer ${props.token}` // Pass the token here
                  }
                });
            setJobsCount(response.data.length);
        } catch (error) {
            console.error("Error fetching jobs count:", error);
        }
    };

    const handleButtonClick = (formName) => {
        setSearchParams({ activeForm: formName });
        setActiveForm(formName);
    };

    // Breadcrumb logic to map active form to a readable label
    const getBreadcrumbLabel = (formName) => {
        switch (formName) {
            case 'locations':
                return 'Locations';
            case 'contracts':
                return 'Contracts';
            case 'jobs':
                return 'Jobs';
            case 'upload':
                return 'Upload Jobs';
            case 'lcats':
                return 'LCATs';
            case 'uploadKR':
                    return 'Convert KR Jobs';
            case 'uploadID':
                return 'Convert ID Jobs';
            case 'uploadTB':
                    return 'Convert TB Jobs';
            case 'uploadTKO':
                return 'Convert TKO Jobs';
            case 'uploadTP2':
                return 'Convert TP2 Jobs';
            case 'tempTokens':
              return 'Temp Tokens';
            default:
                return 'Admin Home';
        }
    };

    // Render the breadcrumb
    const renderBreadcrumbs = () => {
        return (
            <nav className="text-gray-600 mb-4">
                <span className="hover:underline cursor-pointer" onClick={() => handleButtonClick('admin')}>Admin Home</span>
                {activeForm === "lcats" && (
                     <>
                     <span> &gt; </span>
                     <span className="hover:underline cursor-pointer" onClick={() => handleButtonClick('contracts')}>Contracts</span>
                      </>
                )}
                {activeForm !== "admin" && (
                    <>
                        <span> &gt; </span>
                        <span>{getBreadcrumbLabel(activeForm)}</span>
                    </>
                )}
            </nav>
        );
    };

    // Helper function to render the correct form
    const renderForm = () => {
        switch (activeForm) {
            case 'locations':
                return <LocationForm token={props.token} />;
            case 'contracts':
                return <ContractForm token={props.token} setActiveContractId={setActiveContractId} setSearchParams={setSearchParams} setActiveForm={setActiveForm} />;
            case 'jobs':
                return <JobForm token={props.token} />;
            case 'upload':
                return <JobUploadForm token={props.token} />;
            case'lcats':
                return<LCATForm token={props.token} activeContractId={activeContractId} setActiveContractId={setActiveContractId} setActiveForm={setActiveForm}/>;
            case'uploadKR':
                return<ImportKRForm token={props.token} />;
            case'uploadID':
                return<ImportIDForm token={props.token} />;
            case'uploadTB':
                return<ImportTBForm token={props.token} />;
            case'uploadTKO':
                return<ImportTKOForm token={props.token} />;
            case'uploadTP2':
                return<ImportTP2Form token={props.token} />;
              case'tempTokens':
                return<TokenAdminForm token={props.token} />;
            case "admin":
                return (
                    <div className="w-full p-4 flex justify-center bg-gray-100">
                    <div className="w-full max-w-md mt-8 bg-gray-100">
                      <h1 className="text-2xl font-bold text-center mb-8 text-gray-700">
                        Kontana Jobs Administration
                      </h1>
                      <div className="border border-gray-300 p-6 rounded-lg shadow-lg bg-white">
                        <button
                          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-6"
                          onClick={() => handleButtonClick('locations')}
                        >
                          Locations ({locationsCount})
                        </button>
                        <button
                          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-6"
                          onClick={() => handleButtonClick('contracts')}
                        >
                          Contracts ({contractsCount})
                        </button>
                        <button
                          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-6"
                          onClick={() => handleButtonClick('jobs')}
                        >
                          Jobs ({jobsCount})
                        </button>
                        <button
                          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-6"
                          onClick={() => handleButtonClick('upload')}
                        >
                          Upload Jobs
                        </button>
                        <button
                          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-6"
                          onClick={() => handleButtonClick('tempTokens')}
                        >
                          Temp Tokens
                        </button>

                        <div className="border border-gray-300 mt-3 p-3 rounded-lg shadow-lg bg-white">
                          <h3 className="text-xl font-bold text-center mb-2 text-gray-700">
                            Convert Job Files
                          </h3>
                  
                          {/* Grid layout with 3 columns */}
                          <div className="grid grid-cols-3 gap-4">
                            <button
                              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-6"
                              onClick={() => handleButtonClick('uploadID')}
                            >
                              ID
                            </button>
                            <button
                              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-6"
                              onClick={() => handleButtonClick('uploadKR')}
                            >
                              KR
                            </button>
                            <button
                              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-6"
                              onClick={() => handleButtonClick('uploadTB')}
                            >
                              TB
                            </button>
                            <button
                              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-6"
                              onClick={() => handleButtonClick('uploadTKO')}
                            >
                              TKO
                            </button>
                            <button
                              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-6"
                              onClick={() => handleButtonClick('uploadTP2')}
                            >
                              TP2
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                
                );
            default:
                return null; // Default, render nothing
        }
    };

    return (

        <div className="w-full p-4">
            {/* Render the breadcrumb */}
            {renderBreadcrumbs()}

            <div className="mt-8 w-full">
                {/* Render the selected form */}
                {renderForm()}
            </div>
        </div>
    );
};

export default AdminHomePage;
