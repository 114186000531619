import {api_url} from '../../config';

export function findSelected(item, items) {
    for (let i = 0; i < items.length; i++) {
        if (items[i].name === item) {
            return i;
        }
    }
    return 0;
}

export function nameExists(itemName, list) {
    for (let i = 0; i < list.length; i++) {
        if (list[i].name === itemName) {
            return (list[i]._id);
        }
    }   
    return undefined;
}

export function convertAltNamesArrayToString(altNames){
  let outputValue = '';
  if ( altNames !== undefined && altNames.length > 0)
  altNames.map(altName =>{
      if ( outputValue !== '')
      outputValue += ",";
      outputValue += altName;
  })

  return outputValue;
}

export function convertAltNamesStringToArray(altNames){
  let altNamesOut = [];
  if ( altNames !== undefined && altNames.length > 0)
  altNames.split(',').map(line => {
      altNamesOut.push(line);
  })
  return altNamesOut;
}

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  };     

export function cloneName(itemName, list){
    let uniqueNameFound = false;
    let cloneName = itemName
    while (!uniqueNameFound){

      cloneName = "Clone of " + itemName
      if (!nameExists(cloneName,list)) {
          return cloneName
      }
    }
  }

  export async function cloneItem(newItem, list, setListFunction, apiString ){

    const response = await fetch(api_url + apiString, {
      method: "POST",
      credentials: 'include',
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify(newItem),
  })
      .catch(error => {
          window.alert(error);
          return;
  });

    const clonedItem = await response.json();
    const newList =updateItemInList(clonedItem, list);
    setListFunction(newList);
  }

  export function updateItemInList(newItem, list) {
    const newList = [...list];
    let found = false;
    for (let i = 0; i < newList.length; i++) {
      if (newList[i]._id === newItem._id) {
        newList[i] = newItem;
        found = true;
        break;
      }
    }

    if (!found) {
        newList.push(newItem);
    }
    return newList;
  }

  export function deleteAll(selectedIds, list, setListFunction, apiString){
    fetch(api_url + apiString, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ids: selectedIds }),
    })
    .then(data => {
      console.log('Success:', data);
      let newList = list.filter( (item) => {
        return !selectedIds.includes(item._id)
      });
      setListFunction(newList);
    }).catch((error) => {
      console.error('Error:', error);
    });
  }

  export function deleteOne(listItem, list, setListFuction, apiString){
    fetch(api_url + apiString + listItem._id, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => {
      console.log('Success:', data);
      let newList = list.filter( (item) => {
        return listItem._id === item._id
      });
      setListFuction(newList);
    }).catch((error) => {
      console.error('Error:', error);
    });
  }
