import './index.css';
import React, { useState, useEffect} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useCookies } from "react-cookie";
import NavigationBar from './components/NavigationBar';
import Redirect404 from './pages/Redirect404';

import LoginPage from "./pages/LoginPage";
import Logout from "./pages/LogoutPage"
import PrivateRoute from "./components/PrivateRoute";
import { api_url } from "./config";
import { isLoggedIn, userIsAdmin } from './util/verifyLogin';
import JobsFilterPage from './pages/JobsFilterPage';
import JobDetail from './components/jobs/JobDetail';
import { getFilteredJobs } from './util/filters'; // Add this line
import FileUpload from './pages/FileUpload';
import LocationForm from './pages/LocationForm';
import ContractForm from './pages/ContractForm';
import AdminHomePage from './pages/AdminHome';
import LCATForm from './pages/LCATForm';
import JobForm from './pages/JobForm';

function App() {

  const [showNavigationBar, setShowNavigationBar] = useState(true);
  const [queryState, setQueryState] = useState({
    query: '',
    filteredJobs: []
    })
  const [cookies, removeCookie] = useCookies([]);
  const [state, setState] = useState({
    profile: cookies.profile || {},
    loggedIn: ( getCookieState() )})


  function getCookieState(){
    const cookieState = cookies.token !== undefined && cookies.token !== 'undefined' 
    return cookieState
  }

  const [jobs, setJobs] = useState([]);
  const [jobsLoading, setJobsLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [locations, setLocations] = useState([]);
  const [lcats, setLCATs] = useState([]);
  const [users, setUsers] = useState([]);

    const handleChange = (e) => {
      e.preventDefault();
      queryState.query = e.target.value;
      const results = getFilteredJobs(jobs,queryState);
      setQueryState({
        query: e.target.value,
        filteredJobs: results
      })
      };
    

      function clearState(){
        console.log("Unable to authenticate with server, Unauthorized");
        removeCookie('profile');
        removeCookie('token');
        setState({ profile: {}, loggedIn: false });
      }

    // This method fetches the jobs from the database.
    useEffect(() => {
      async function getJobs() {
        // console.log("State- " + state)
        if (isLoggedIn(cookies, state)){
          const response = await fetch(api_url + `/api/jobs`, {credentials: 'include' });
      
          if (!response.ok) {
            if (response.status === 403){
              clearState()
            }else{
              const message = `An error occurred: ${response.statusText}`;
              window.alert(message);
            }
            return;
          }
      
          const jobs = await response.json();
          setJobs(jobs);
          setQueryState({
            query: queryState.query,
            filteredJobs: getFilteredJobs(jobs, queryState)
          })
          setJobsLoading(false);
        }
      }
      getJobs();
    }, [jobs.length, queryState.filteredJobs.length]);

      
      useEffect(() => {
      async function getLocations() {
        if (isLoggedIn(cookies, state)){
          const response = await fetch(api_url + `/api/locations`, {credentials: 'include' });
      
          if (!response.ok) {
            if (response.status === 403){
              clearState()
            }else{
              const message = `An error occurred: ${response.statusText}`;
              window.alert(message);
            }
            return;
          }
      
          const locations = await response.json();
          setLocations(locations);
        }
      }
    
      getLocations();
    }, [locations.length]);

      useEffect(() => {
      async function getCompanies() {
        if (isLoggedIn(cookies, state)){
          const response = await fetch(api_url + `/api/companies`, {credentials: 'include' });
      
          if (!response.ok) {
            if (response.status === 403){
              clearState()
            }else{
              const message = `An error occurred: ${response.statusText}`;
              window.alert(message);
            }
            return;
          }
      
          const companies = await response.json();
          setCompanies(companies);
        }
      }
    
      getCompanies();
    }, [companies.length]);

      useEffect(() => {
      async function getContracts() {
       if (isLoggedIn(cookies, state)){
        const response = await fetch(api_url + `/api/contracts`, {credentials: 'include' });
      
          if (!response.ok) { 
            if (response.status === 403){
              clearState()
            }else{
              const message = `An error occurred: ${response.statusText}`;
              window.alert(message);
            }
            return;
          }
      
          const contracts = await response.json();
          setContracts(contracts);
        }
      }
      
      getContracts();
    }, [contracts.length]);

      useEffect(() => {
      async function getLCATs() {
        if (isLoggedIn(cookies, state)){
          const response = await fetch(api_url + `/api/lcats`, {credentials: 'include' });
      
          if (!response.ok) { 
            if (response.status === 403){
              clearState()
            }else{
              const message = `An error occurred: ${response.statusText}`;
              window.alert(message);
            }
            return;
          }
      
          const lcats = await response.json();
          setLCATs(lcats);
        }
      }
     
      getLCATs();
    }, [lcats.length]);
       
      useEffect(() => {
       async function getUsers() {
        if (isLoggedIn(cookies, state)){
        const response = await fetch(api_url + `/api/users`, {credentials: 'include' });
     
         if (!response.ok) { 
          if (response.status === 403){
            clearState() 
          }else{
            const message = `An error occurred: ${response.statusText}`;
            window.alert(message);
          }
           return;
         }
     
         const users = await response.json();
         setUsers(users);
       }     
      }
       getUsers();
      }, [users.length]);


  return (
    
    <div className='h-screen overflow-visible'>
       <BrowserRouter>
          { showNavigationBar && <NavigationBar queryState={queryState} handleQueryChange={handleChange}
               state={state}/>}
          <Routes>
            <Route path="/" element={<JobsFilterPage setShowNavigationBar={setShowNavigationBar}/>} />
            <Route path="/jobs" element={<JobsFilterPage setShowNavigationBar={setShowNavigationBar}/>} />
            <Route exact path='/job/:uuid' element={<JobDetail setShowNavigationBar={setShowNavigationBar} /> } />
            <Route path="/admin" element={<AdminHomePage setShowNavigationBar={setShowNavigationBar}/>} />
            <Route path="/admin/upload-jobs" element={<FileUpload setShowNavigationBar={setShowNavigationBar}/>} />
            <Route path="/admin/locations" element={<LocationForm setShowNavigationBar={setShowNavigationBar}/>} />
            <Route path="/admin/contracts" element={<ContractForm setShowNavigationBar={setShowNavigationBar}/>} />
            <Route path="/admin/jobs" element={<JobForm setShowNavigationBar={setShowNavigationBar}/>} />
            <Route path="/admin/contracts/:contractId/lcats" element={<LCATForm setShowNavigationBar={setShowNavigationBar} />} />
            {/* <Route exact path='/profile' element={<PrivateRoute open={isLoggedIn(cookies, state)} />}>
               <Route path='/profile' element={<ProfilePage setShowNavigationBar={setShowNavigationBar} state={state}/>}/>
            </Route> */}
            <Route path='/login' element={<LoginPage setShowNavigationBar={setShowNavigationBar} open={isLoggedIn(cookies, state)} setState={setState} />}/>
            <Route path="/logout" element={<Logout setShowNavigationBar={setShowNavigationBar} removeCookie={removeCookie} setState={setState} />} />

            <Route path="*" element={<Redirect404 setShowNavigationBar={setShowNavigationBar} />} /> 
        </Routes>
      </BrowserRouter>
    </div>
  );  
}
export default App;