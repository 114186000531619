import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ClipboardIcon } from '@heroicons/react/24/outline';
import { api_url } from '../../config';
import Spinner from '../Spinner';

import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';

export default function JobDetail(props) {
  const { uuid } = useParams();
  const [job, setJob] = useState(props.job || null);
  const [loaded, setLoaded] = useState(!!props.job);
  const [error, setError] = useState(null);


  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert('URL copied to clipboard!');
  };


  useEffect(() => {
    window.scrollTo(0, 0);  // Scroll to top on load
    if (!props.job) {
      const tempTokenParam = props.tempToken? `?token=${props.tempToken}`:``;
      const fetchJob = async () => {
        try {

          const headers = props.token
          ? { Authorization: `Bearer ${props.token}` }
          : {}; // Include headers only if token exists
      
          const response = await fetch(`${api_url}/api/job/${uuid}` + tempTokenParam,
            {
              headers,
            });
          if (response.ok) {
            const jobData = await response.json();
            setJob(jobData);
          } else {
            setError("Job does not exist or not found");
          }
        } catch (err) {
          setError("An error occurred while fetching the job");
        } finally {
          setLoaded(true);
        }
      };
      fetchJob();
    }
  }, [uuid, props.job]);

  if (!loaded) {
    return <div><Spinner/></div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="w-full p-4">
      <div className="w-full md:w-3/4 lg:w-2/3 mx-auto">

        {/* Job Details */}
        <div className="bg-white p-6 border border-gray-300 rounded-b-md">
          <h1 className="text-2xl font-bold text-gray-800">{job.lcat.name}</h1>

          <div className="mt-2 flex items-center space-x-4">
            <span className="text-gray-600">{job.name}</span>
            <button
              className="flex items-center space-x-2 bg-gray-800 px-2 py-1 text-white text-sm rounded"
              onClick={() => copyToClipboard(window.location.href)}
            >
              <ClipboardIcon className="h-4 w-4" />
              <span>Copy URL</span>
            </button>
          </div>
          
          <div className="mt-4">
            <h3 className="font-semibold text-gray-700">Contract / TTO</h3>
            <p className="text-gray-600">{job.contract.name} / {job.tto}</p>
          </div>

          {(job.subTask !== undefined && job.subTask !== "") && (<div className="mt-4">
            <h3 className="font-semibold text-gray-700">Sub Task</h3>
            <p className="text-gray-600">{job.subTask}</p>
          </div>)}

          {job.fte !== 1 && (<div className="mt-4">
            <h3 className="font-semibold text-gray-700">FTE</h3>
            <p className="text-gray-600">{job.fte}</p>
          </div>)}

          <div className="mt-4">
            <h3 className="font-semibold text-gray-700">Location</h3>
            <p className="text-gray-600">{job.location.name}</p>
          </div>

          <div className="mt-4">
            <h3 className="font-semibold text-gray-700">Telework</h3>
            <p className="text-gray-600">{job.teleworkAvailable}</p>
          </div>

          {job.description !== "" && (<div className="mt-4">
            <h3 className="font-semibold text-gray-700">Description</h3>
            <div className="text-gray-600">
              <ReactMarkdown remarkPlugins={[remarkBreaks]} components={{
          ul: ({ children }) => <ul className="list-disc ml-6">{children}</ul>,
          ol: ({ children }) => <ol className="list-decimal ml-6">{children}</ol>,
          li: ({ children }) => <li className="mb-2">{children}</li>,
        }}>{job.description}</ReactMarkdown>

            </div>
          </div>)}

          {job.requiredSkills !== "" && (<div className="mt-4">
            <h3 className="font-semibold text-gray-700">Required Skills</h3>
            <div className="text-gray-600">
              <ReactMarkdown remarkPlugins={[remarkBreaks]} components={{
          ul: ({ children }) => <ul className="list-disc ml-6">{children}</ul>,
          ol: ({ children }) => <ol className="list-decimal ml-6">{children}</ol>,
          li: ({ children }) => <li className="mb-2">{children}</li>,
        }}>{job.requiredSkills}</ReactMarkdown>
            </div>
          </div> )}

          {job.desiredSkills !== "" && (<div className="mt-4">
            <h3 className="font-semibold text-gray-700">Desired Skills</h3>
            <div className="text-gray-600">
              <ReactMarkdown remarkPlugins={[remarkBreaks]} components={{
          ul: ({ children }) => <ul className="list-disc ml-6">{children}</ul>,
          ol: ({ children }) => <ol className="list-decimal ml-6">{children}</ol>,
          li: ({ children }) => <li className="mb-2">{children}</li>,
        }}>{job.desiredSkills}</ReactMarkdown>
            </div>
          </div>)}

          <div className="mt-4">
            <h3 className="font-semibold text-gray-700">Years Experience Required</h3>
            <table className="table-auto w-full mt-2 border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-200 text-gray-700">
                  <th className="border border-gray-300 px-2 py-1">No Degree</th>
                  <th className="border border-gray-300 px-2 py-1">BA/BS</th>
                  <th className="border border-gray-300 px-2 py-1">MA/MS</th>
                  <th className="border border-gray-300 px-2 py-1">PhD</th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-center">
                  <td className="border border-gray-300 px-2 py-1">{job.lcat.noDegree}</td>
                  <td className="border border-gray-300 px-2 py-1">{job.lcat.bachelors}</td>
                  <td className="border border-gray-300 px-2 py-1">{job.lcat.masters}</td>
                  <td className="border border-gray-300 px-2 py-1">{job.lcat.doctorate}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
