
function get_api_url() {
    if (process.env.REACT_APP_API_URL !== undefined){
        return process.env.REACT_APP_API_URL;
    }
    return "http://localhost:5000";
}

function get_client_url(){ 
    if (process.env.REACT_APP_CLIENT_URL !== undefined){
        return process.env.REACT_APP_CLIENT_URL;
    }
    return "http://localhost:3000";
}

export const api_url = get_api_url();
export const client_url = get_client_url();