import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
// import { useSearchParams } from 'react-router-dom';
// import LoginFailureNotice from "../components/LoginFailureNotice";


export default function LogoutPage(props) {

  // const [searchParams] = useSearchParams();
  // const temp = searchParams.get('error') !== null ? searchParams.get('error').toLowerCase() : "";
  // const [errors, setErrors] = useState(temp);
  const [logoutCompleted, setLogoutCompleted] = useState(false);

  props.setShowNavigationBar(true);
  useEffect(() => {
    props.setState({ profile: {}, loggedIn: false });
    props.removeCookie('profile');
    props.removeCookie('token');
    setLogoutCompleted(true);
   
  }, []);

  return (
    <div>
        {/* { errors !== "" && logoutCompleted === true && <LoginFailureNotice message={errors} setErrors={setErrors} /> } */}
        {  logoutCompleted === true && <Navigate to="/login" /> }
      </div>
  );
}