export function isLoggedIn(cookies, state){
    // console.log("checking logged in status");
    try{
        let loggedIn = ( state.loggedIn &&
            cookies.token !== 'undefined' &&
            cookies.token.value !== "undefined");
        
            // console.log( loggedIn);
        return loggedIn;
    }
    catch (exc) {
        // console.log("returning false - " + exc);
        return false;
    }
}

export function userIsAdmin(state){
    if ( 
          state !== 'undefined' &&
          state.profile !== 'undefined'){
        let isAdmin = state.profile.role === 'admin';
        return isAdmin;
    }
    return false;
}