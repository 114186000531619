import React, { useState } from 'react';
import axios from 'axios';
import { api_url } from '../config';

const ImportTP2Form = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      setUploadStatus('Please select a file.');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axios.post( api_url + '/api/admin/tp2/process', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
           Authorization: `Bearer ${props.token}`, // Pass the token here
        },
      });
      setUploadStatus('File processed successfully!');
      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'tko_jobs.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      setUploadStatus(`Error processing file: ${error.message}`);
    }
  };

  return (
    <div className="max-w-xl mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-xl font-semibold text-center mb-4">THEPROGRAM2 Job File Management</h1>

        <form onSubmit={handleFileUpload} className="space-y-6">
          <div>
            <label htmlFor="fileInput" className="block text-sm font-medium text-gray-700">
              Select File
            </label>
            <input
              type="file"
              id="fileInput"
              onChange={handleFileChange}
              accept=".csv,.xlsx,.xls"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          <button
            type="submit"
            className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
          >
            Process File
          </button>
        </form>

        {uploadStatus && (
          <div className="mt-4 text-center">
            <p className={`text-sm font-semibold ${uploadStatus.includes('successfully') ? 'text-green-600' : 'text-red-600'}`}>
              {uploadStatus}
            </p>
          </div>
        )}
      </div>
  );
};

export default ImportTP2Form;