import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { api_url } from '../config';

Modal.setAppElement('#root'); // For accessibility

const ImportIDForm = (props) => {
  const [files, setFiles] = useState([]);
  const [documentFiles, setDocumentFiles] = useState([]);
  const [csvFiles, setCSVFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [processingFile, setProcessingFile] = useState(null);

  // Handle file input
  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    try {
      const response = await axios.get(api_url + '/api/admin/id/files',
        {
          headers: {
            Authorization: `Bearer ${props.token}` // Pass the token here
          }
        });
      // Filter files: Only show `.docx` files in modal
      const filteredDocFiles = response.data.files.filter((file) =>
        file.fileName.endsWith('.docx')
      );
      const filteredCSVFiles = response.data.files.filter((file) =>
        file.fileName.endsWith('.xlsx') ||  file.fileName.endsWith('.csv')
      );

      setDocumentFiles(filteredDocFiles);
      setCSVFiles(filteredCSVFiles);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  // Handle file upload
  const handleFileUpload = async () => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
      console.log(files[i]);
    }

    try {
      const res = await axios.post(api_url + '/api/admin/id/upload', formData, {
        headers: {
          Authorization: `Bearer ${props.token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Files uploaded successfully');
      setFiles([]); // Clear the selected files
      fetchFiles();
    } catch (error) {
      console.error('Error uploading files:', error);
      alert('Error uploading files');
    }
  };

  // Get the list of uploaded files
  const getUploadedFiles = async () => {
    try {
      setShowModal(true);
    } catch (error) {
      console.error('Error fetching files:', error);
      alert('Error fetching files');
    }
  };

  // Handle file processing
  const handleProcessFile = async () => {
    if (!processingFile) {
      alert('Please select a file to process.');
      return;
    }

    try {
      const res = await axios.get(api_url + `/api/admin/id/process?file=${processingFile}`, {
        headers: {
          Authorization: `Bearer ${props.token}`, // Pass the token here
        },
      });

      if ( res.status === 200){
        const blob = new Blob([res.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'id_jobs.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        fetchFiles();
      }else{
        alert("Recieved a " + res.status + " status code:\r\n" + res.data);
      }
    } catch (error) {
      // Check if the error contains a response (i.e., axios caught an HTTP error)
      if (error.response) {
        // Extract response data from the error object
        const { status, data } = error.response;
        alert(`Received a ${status} status code:\r\n${JSON.stringify(data, null, 2)}`);
      } else {
        alert('Error processing file: \r\n' + error.message);
      }
    }
  };

  return (
    <div className="max-w-xl mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-xl font-semibold text-center mb-4">IMAGINEDRAGON Job File Management</h1>

      {/* File Upload Section */}
      <div className="mb-6">
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          accept=".docx,.xlsx,.csv,.zip"
          className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
        />
        <button
          className="mt-4 px-4 py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700"
          onClick={handleFileUpload}
        >
          Upload Files
        </button>
      </div>

      {/* Process File Section */}
      <div className="mb-6">
        <label className="block mb-2 text-sm font-medium">Select an Excel or CSV file to process:</label>
        <select
          className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 p-2"
          onChange={(e) => setProcessingFile(e.target.value)}
        >
          <option value="">Select a file</option>
          {/* Only show `.xlsx` and `.csv` files in process dropdown */}
          {csvFiles
            .map((file, idx) => (
              <option key={idx} value={file.fileName}>
                {file.fileName}
              </option>
            ))}
        </select>
        <button
          className="mt-4 px-4 py-2 bg-green-600 text-white font-semibold rounded-md hover:bg-green-700"
          onClick={handleProcessFile}
        >
          Create Job Import File
        </button>
      </div>

      {/* List Files Button */}
      <div className="mb-6">
        <button
          className="px-4 py-2 bg-gray-600 text-white font-semibold rounded-md hover:bg-gray-700"
          onClick={getUploadedFiles}
        >
          List Uploaded ID Documents
        </button>
      </div>

      {/* Modal for showing uploaded files */}
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)} // Close modal when clicking outside of it
        className="flex justify-center items-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
          <h2 className="text-lg font-semibold mb-4">Uploaded .docx Files</h2>
          <ul className="list-disc pl-5">
            {/* Show only .docx files */}
            {documentFiles.map((file, idx) => (
              <li key={idx}>
                {file.fileName} (Uploaded on: {new Date(file.uploadDate).toLocaleString()})
              </li>
            ))}
          </ul>
          <button
            className="mt-6 px-4 py-2 bg-red-600 text-white font-semibold rounded-md hover:bg-red-700"
            onClick={() => setShowModal(false)}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ImportIDForm;
