import { MapPinIcon, BriefcaseIcon, CurrencyDollarIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom'; // Import useNavigate

export default function JobRow(props) {
    const navigate = useNavigate(); // Initialize useNavigate

    function rowClickHandler() {
      handleRowClick(props.job._id);
    }

    function handleRowClick(jobId) {
      // Save the scroll position in session storage
      sessionStorage.setItem("scrollPosition", window.scrollY);
      // Navigate to the job detail page
      navigate(`/job/${jobId}`);
    }
    
    function allowsRemoteText(teleworkAvailable) {
        return teleworkAvailable === undefined || teleworkAvailable === "" || teleworkAvailable === "No" ? "" : "/Telework - " + teleworkAvailable;
    }

    return (
        <li key={props.job._id}>
            {/* Replace <a> with a div and handle onClick */}
            <div onClick={rowClickHandler} className="block hover:bg-gray-50 cursor-pointer">
                <div className="px-4 py-4 sm:px-6">
                    <div className="flex items-center justify-between">
                        <p className="truncate text-sm font-medium text-indigo-600">{props.job.name} {props.job.fte > 1 && (" (" + props.job.fte + " FTEs)")} </p>

                        <div className="ml-2 flex flex-shrink-0">
                            <p className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                {/* {props.job.tto} {props.job.subTask ? "/": ""} {props.job.subTask} */}
                                {props.job.tto} 
                            </p>
                        </div>
                    </div>
                    <div className="mt-2 sm:flex sm:justify-between">
                        <div className="sm:flex">
                            <p className="flex items-center text-sm text-gray-500">
                                <BriefcaseIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-black-400" aria-hidden="true" />
                                {props.job.lcat.name} on {props.job.contract.name}
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                                <MapPinIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-blue-400" aria-hidden="true" />
                                {props.job.location.name}{allowsRemoteText(props.job.teleworkAvailable)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    );
}
