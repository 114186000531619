import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { api_url } from '../config';

const LocationForm = (props) => {
  const [locations, setLocations] = useState([]);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [newLocationName, setNewLocationName] = useState('');

  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchLocations = async () => {
    try {
      const response = await axios.get( api_url + '/api/locations');
      setLocations(response.data);
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  const handleDeleteLocation = async (id) => {
    try {
      await axios.delete(api_url + `/api/location/${id}`);
      setLocations(locations.filter(location => location._id !== id));
    } catch (error) {
      console.error("Error deleting location:", error);
    }
  };

  const handleAddLocation = async () => {
    try {
      const response = await axios.post(api_url + '/api/location', { name: newLocationName });
      setLocations([...locations, response.data]);
      setShowAddDialog(false);
      setNewLocationName('');
    } catch (error) {
      console.error("Error adding location:", error);
    }
  };

  return (

    <div className="w-full p-4">
    <div className="w-full md:w-3/4 lg:w-2/3 mx-auto">
      <h2 className="text-xl font-bold mb-4">Locations</h2>

      <ul className="mb-4">
        {locations.map(location => (
          <li key={location._id} className="flex justify-between items-center p-2 bg-gray-100 rounded mb-2">
            <span>{location.name}</span>
            <button
              className="text-red-500 hover:text-red-700"
              onClick={() => handleDeleteLocation(location._id)}
            >
              X
            </button>
          </li>
        ))}
      </ul>

      <button
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
        onClick={() => setShowAddDialog(true)}
      >
        Add New Location
      </button>

      {showAddDialog && (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-75">
          <div className="bg-white p-6 rounded">
            <h3 className="text-lg font-semibold mb-4">Add New Location</h3>
            <input
              type="text"
              className="border border-gray-300 rounded w-full p-2 mb-4"
              value={newLocationName}
              onChange={(e) => setNewLocationName(e.target.value)}
              placeholder="Enter location name"
            />
            <div className="flex justify-end">
              <button
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
                onClick={() => setShowAddDialog(false)}
              >
                Cancel
              </button>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
                onClick={handleAddLocation}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}
      </div>
    </div>
  );
};

export default LocationForm;
